//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {

	data()
	{
		return {
			displayClasses: ''
		}
	},

	mounted()
	{
		if (typeof window!=='undefined')
		{
			setTimeout(()=>
			{
				let scrollArea = document.querySelector('.scroll-content');
				if (scrollArea)
				{
					if (scrollArea.clientHeight / window.innerHeight > 1.5)
					{
						// this.displayStyle = 'display: inline-block;';
						this.displayClasses = 'scroll-up--visible';
					}
				}
				else
				{
					if (document.body.clientHeight / window.innerHeight > 1.5)
					{
						this.displayClasses = 'scroll-up--visible';
					}
				}
			}, 100)
			// this.$nextTick(() =>
			// {

			// })
		}
	}
}
