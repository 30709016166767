// taken from https://github.com/heavyy/vue-intersect

import Vue from 'vue'
const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
// import 'intersection-observer' // this causes safari to endlessly reload

const warn = (msg) => {
	if (!Vue.config.silent) {
		consola.warn(msg)
	}
}

function addClasses(el, classes)
{
	if (!classes)	return;
	// debugger
	if (typeof classes == "string")
		el.classList.add(classes);
	else if ( Array.isArray(classes) )
		for (let i = 0; i < classes.length; i++)
			el.classList.add(classes[i]);
}

function removeClasses(el, classes)
{
	if (!classes)	return;
	if (typeof classes == "string")
		el.classList.remove(classes);
	else if ( Array.isArray(classes) )
		for (let i = 0; i < classes.length; i++)
			el.classList.remove(classes[i]);
}

export default {
	name: 'intersect',
	abstract: true,
	props:
	{
		threshold:
		{
			type: Array,
			required: false,
			default: () => [0, 0.2]
		},
		root:
		{
			// type: HTMLElement,
			type: Object,
			required: false,
			default: () => null
		},
		rootMargin:
		{
			type: String,
			required: false,
			default: () => '0px 0px 0px 0px'
		},
		once:
		{
			type: Boolean,
			default: false,
		},

		destroyTimeout:
		{
			type: Number,
			default: 0,
		},

		destroyClass:
		{
			type: String,
			default: 'observing-finished'
		},

		classDefault:
		{
			type: String,
			default: '',
		},

		classOnEnter:
		{
			type: [String, Array],
			default: null,
		},
		classOnLeave:
		{
			type: [String, Array],
			default: null,
		},
	},

	data()
	{
		return {
			entered: false,
		}
	},

	created ()
	{
		this.observer = null;
		// if (process.browser)
		// 	consola.debug('rootMargin: ', this.rootMargin)
		if (typeof IntersectionObserver !== 'undefined')
		{
			this.observer = new IntersectionObserver((entries) =>
			{
				let el = this.$slots.default[0].elm;
				if (!entries || !entries.length)	return;
				// consola.debug('entries: ', entries)
				let isIntersecting = entries[0].isIntersecting;
				let rect = entries[0].boundingClientRect;
				if (rect.width == 0 || rect.height==0)
				{
					rect = el.getBoundingClientRect();
					if (rect.bottom <= 0 || rect.top > window.innerHeight)
					{
						isIntersecting = false;
					}
					else
					{
						isIntersecting = true;
					}
				}
				if (!isIntersecting && this.entered)
				{
					// consola.debug('vue-intersect: onLeave', entries[0])
					this.entered = false;
					removeClasses(el, this.classOnEnter);
					addClasses(el, this.classOnLeave);
					this.$emit('leave', [entries[0]])
					// if (this.once)
					// {
					// 	this.observer && this.observer.disconnect();
					// 	this.observer = null;
					// }
				}
				else if (isIntersecting && !this.entered)
				{
					// consola.debug('vue-intersect: onEnter', entries[0])
					this.entered = true;
					removeClasses(el, this.classOnLeave);
					addClasses(el, this.classOnEnter);
					this.$emit('enter', [entries[0]])
					if (this.once)
					{
						this.observer && this.observer.disconnect();
						this.observer = null;
						const destroyClass = this.destroyClass;
						setTimeout( ()=>
						{
							addClasses(el, destroyClass);
						}, this.destroyTimeout )
					}
				}

				// consola.debug(`vue-intersect: onChange [${this.entered}]`, entries[0])
				this.$emit('change', [entries[0], rect])
			},
			{
				threshold: this.threshold,
				root: this.root,
				rootMargin: this.rootMargin
			})
		}
		else
		{
			if (process.browser)
				(console.error || consola.debug)('IntersectionObserver is not supported by this browser')
		}
	},

	mounted ()
	{

		// consola.debug('classOnEnter: ', this.classOnEnter);
		this.$nextTick(() =>
		{
			if (this.$slots.default && this.$slots.default.length > 1)
			{
				warn('[VueIntersect] You may only wrap one element in a <intersect> component.')
			}
			else
				if (!this.$slots.default || this.$slots.default.length < 1)
				{
					warn('[VueIntersect] You must have one child inside a <intersect> component.')
					return
				}

			let el = this.$slots.default[0].elm;
			addClasses(el, this.classDefault);


			this.observer && this.observer.observe(this.$slots.default[0].elm)
		})
	},

	destroyed ()
	{
		this.observer && this.observer.disconnect()
	},

	render (h)
	{
		// consola.debug('this.$slots.default[0]', this.$slots.default[0])
		// if (this.$slots.default)
		// {

		// 	// return this.$slots.default[0];
		// 	return h(this.$slots.default[0].elm, {
		// 		'class': (this.entered ? this.classOnEnter : this.classOnLeave)
		// 	})
		// }
		// else
		// 	return null
		return this.$slots.default ? this.$slots.default[0] : null
	}
}
