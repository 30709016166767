//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Dropdown from '~/components/dropdown'

export default {

	components:
	{
		'v-dropdown': Dropdown
	},

	props:
	{
		strName:
		{
			required: true,
		},

	},

	data()
	{
		return {
			to: '',
			opensInNewTab: false,
			uid: 0,

			hasFocus: false,
			is_dirty: false,
			postData: Object.freeze({}),
			// content: ''
		}
	},

	computed:
	{
		...mapGetters(['isEditingPage', 'getString']),
		// isDirty()	{	return this.is_dirty	},
		// isEditable() { return this.isEditingPage || this.editable; },
		// isSlotEmpty() {	return !this.content },
		// isSlotEmpty() {	return !this.$slots.default || !this.$slots.default.innerText },
		// emptyStyle()
		// {
		// 	if (this.isEditable && this.field && this.isSlotEmpty)
		// 		return { '--placeholder-text': `"<${this.field}>"` }
		// 	return {}
		// }
	},

	watch:
	{
		hasFocus(newVal)
		{
			if ( newVal==false )
			{
				this.validate()
			}
		},

		value(newVal)
		{
			// if ( !this.isEditable )	return;
			this.to = newVal;
			// this.$refs['tag'].innerHTML = newVal;
		},

		to(newVal)
		{
			this.$emit('input', newVal);
			this.is_dirty = true;
		},
	},

	created()
	{
		this.uid = Math.ceil( Math.random() * 999999 );
		this.to = this.getString( this.value );
	},

	methods:
	{
		onDropdown(event)
		{
			consola.debug('onDropdown: ', this.$refs.urlInput)
			this.$refs.dropdown.adjust()

			if (this.$refs.urlInput)
			{
				this.$nextTick( () => this.$refs.urlInput.focus() )
				// this.$refs.urlInput.focus()
			}
			// if (event)
			// {
			// 	this.$refs.dropdown.adjust()
			// }
			// else
			// {
			// 	this.showList = true;
			// }
		},

		onInput(event)
		{
			// consola.debug('onInput: ', event)
			this.is_dirty = true;
			// this.$emit('update:value', this.content); // use with .sync
			// this.asyncCommit()
		},

		validate()
		{
			consola.debug('validate')
			this.is_dirty = true;
			this.asyncCommit()
			this.onChange();
		},

		onChange(event)
		{
			consola.debug('onChange: ', event)
			// this.content = event.target.innerText;
			// this.content = this.$refs['tag'].innerHTML;
			this.$emit('input', this.to);
			// this.$emit('update:value', this.content);
		},

		addUpdateData(obj)
		{
			let data = {...this.postData, ...obj};
			this.postData = Object.freeze(data);
		},

		calcUpdateData()
		{
			let obj = {};
			if (this.field.length > 0)
				obj[this.field] = this.url;

			let data = {...this.postData, ...obj};
			this.postData = Object.freeze(data);

			return this.postData;
		},


		sendDataToAncestor()
		{
			let parent = this.$parent
			// let id = -1
			while ( parent )
			{
				// consola.debug('_componentTag: ', parent.$options._componentTag)
				if (parent.$options._componentTag == 'nuxt')
					break;
				if (parent.$options._componentTag == 'rest-model')
				{
					parent.addUpdateData( { ...this.postData } )
					break
				}

				if (parent == parent.$parent)
					break;

				parent = parent.$parent
			}
		},



		asyncCommit()
		{
			if (this.is_dirty)
			{
				if (this.field.length > 0)
				{
					this.calcUpdateData()
					// debugger

					let emitEvent = false

					if (this.modelId > -1)
					{
						this.addUpdateData( { id: this.modelId } )

						if (this.url.length > 0)
						{
							// return this.$axios.put(this.url, this.postData)
							return this.$apiPut(this.url, this.postData)
											.then( param => { this.is_dirty = false; return param; })
											.catch( error =>
											{
												console.error(error);
											})
						}
						else
						{
							emitEvent = true
							// console.warn('editable-text has NO REST url...')
						}
					}
					else
					{
						emitEvent = true
						// console.warn('editable-text has NO model ID...')
					}

					if (emitEvent)
					{
						// NOTE: emiting events on an event-bus
						// causes *ALL* listeners to respond, regardless
						// of their position in the hierarchy.
						// best avoid that approach

						this.sendDataToAncestor()
					}

				}
				else
					console.warn('rest-link-btn has NO field...')
			}
			else
			{
				console.warn('rest-link-btn is NOT dirty...')
			}

			return Promise.resolve(null)

		},

		async commit()
		{
			consola.debug('saving...');
			return await this.asyncCommit()
		},
	},

}
