//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import find from '../../app/utils/find'
import domel from './domel'
// const config = require("../../config/nuxt")

const restApi_stringsUrl = 'string'

var _singletonCounter = 0;

export default {
	name: 'rest-string',

	components:
	{
		domel
	},

	props:
	{
		editable:
		{
			type: Boolean,
			default: false
		},

		strName:
		{
			type: String,
			required: true
		},

		tag:
		{
			type: String,
			default: 'span'
		},
	},

	data()
	{
		return {
			is_saving: false,
			hasFocus: false,
			is_dirty: false,
			// postData: Object.freeze([])
			postData: null,
			content: '',
			singletonCounter: 0,
		}
	},

	computed:
	{
		...mapGetters(['isEditingPage', 'stringTable', 'getString', 'getStringId', 'lang', 'langShort', 'allLanguages']),

		isDirty()	{	return this.is_dirty	},
		isSaving()	{	return this.is_saving	},
		isEditable() { return this.isEditingPage || this.editable; },
		emptyStyle()
		{
			if (this.isEditable && !this.getString(this.strName))
				return { '--placeholder-text': `"<${this.strName}>"` }
			return {}
		},

		strObject()
		{
			return this.stringTable[this.strName] || this.stringTable[this.strName.toUpperCase()] || {value: this.strName};
		},

		// translation()
		// {
		// 	return (this.strObject ? this.strObject.value : '');
		// }
	},

	watch:
	{
		hasFocus(newVal)
		{
			if ( newVal==false )
			{
				this.validate()
			}
		},

		// stringTable(newVal)
		// {
		// 	consola.debug('stringTable update')
		// 	// this.$nextTick( ()=> this.$forceUpdate() )
		// 	// this.$forceUpdate();
		// },

		strObject(newVal)
		{
			// consola.debug('strObject update: ', newVal.key, newVal.value)
			this.content = newVal.value;
			// there is a bug in Chrome and it does not autoupdate
			setTimeout( ()=>
			{
				this.$refs['span'].innerHTML = this.content;
			}, 0 )
			// setTimeout(() => {
			// 	this.$forceUpdate();
			// }, 100);
			// ;
		},

		// translation(newVal)
		// {
		// 	consola.debug('translation update: ', newVal)
		// }
	},

	created()
	{
		this.content = this.getString(this.strName) || this.strName;
		this.singletonCounter = _singletonCounter++;
	},

	mounted()
	{
		if ( !(this.stringTable[this.strName] || this.stringTable[this.strName.toUpperCase()]) )
			consola.debug('WARNING: no string found for: ', this.strName)
		// this.$eventBus.$on('rest:model', (data) => this.addUpdateData(data) )
	},

	render(createElement)
	{
		let attrs = { title: "Shared global text", };
		if (this.isEditable)
			attrs.contenteditable = this.isEditable;

		return createElement(
			this.tag,
			{
				ref: 'span',

				// attrs:
				// {
				// 	contenteditable: this.isEditable,
				// 	title: "Shared global text",
				// },
				attrs,

				class:
				{
					'EditableText': true,
					// 'rest-placeholder': true,
					'is-dirty': this.isDirty,
					'rest-string': true,
					// 'has-focus': this.hasFocus,
				},

				style: this.emptyStyle,

				on:
				{
					input: this.onInput,
					change: this.onChange,
					focus: () => this.hasFocus = true,
					blur: () => this.hasFocus = false,
				}
			},
			this.content
			// this.$slots.default
			// // this.$slots.default ?
			// // [
			// // 	...this.$slots.default,
			// // 	createElement('div', { class: 'rest-edit-icon' })
			// // ]
			// // : this.$slots.default
		)
	},

	methods:
	{

		...mapMutations(['setString']),

		onInput(event)
		{
			// consola.debug('onInput: ', event)
			this.is_dirty = true;
			// this.$emit('input', event.target.value);
			// debugger
			// remove formating
			// event.target.innerHTML = event.target.innerText;
			// this.postData = Object.freeze( this.$refs['span'].innerText );

			// this.$eventBus.$emit('rest:string', this)
		},

		validate()
		{
			consola.debug('validate')
			this.is_dirty = true;
			// if ( this.maxlength )
			// {
			// 	event.target.innerText = event.target.innerText.substring(0, this.maxlength)
			// }
			// if ( this.richtext == false )
			{
				// remove formating
				event.target.innerHTML = event.target.innerText;
				this.postData = Object.freeze( this.$refs['span'].innerText );
				// debugger;


				// const strId = this.getStringId(this.strName)
				// if ( strId == -1 )
				// {
				// 	consola.debug(`string ERROR: could not find [${this.strName}]`)
				// }
				// else
				// {
				// 	const obj = {
				// 					_type: 'string',
				// 					locale: this.lang,
				// 					id: strId,
				// 					value: this.postData
				// 				};

				// 	this.$eventBus.$emit('modify:model', { obj: obj,  field: '' });
				// }
			}
			// this.asyncCommit()

			this.onChange()
		},

		onChange(event)
		{
			// consola.debug('onChange: ', event)
			this.content = this.$refs['span'].innerText || this.$refs['span'].innerHTML;

			this.$emit('input', this.content);
			if (this.strObject && this.strObject.id)
			{
				let key = this.stringTable[this.strName] ? this.strName : this.strName.toUpperCase();
				// you cannot modify store items outside the store.
				this.setString({ key, value: this.content })
				// this.strObject.value = this.postData;
				this.$eventBus.$emit('modify:model', { obj: this.strObject,  field: '' });
			}
			else
			{
				consola.debug(`string ERROR: could not find [${this.strName}]`)
			}
		},

		clearAll(list)
		{
			list = list || [];

			for (let i = 0; i < list.length; i++)
			{
				list[i].is_dirty = false
				list[i].postData = null
			}
		},

		asyncSaveAll(list)
		{
			list = list || [];

			let postData = [];
			for (let i = 0; i < list.length; i++)
			{
				const item = list[i]
				const strId = this.getStringId(item.strName)
				if ( strId == -1 || item.postData == null)
				{
					consola.debug(`string ERROR: could not find [${item.strName}]`)
					continue
				}

				const obj = {
					_type: 'string',
					locale: this.lang,
					id: strId,
					value: item.postData
				}
				postData.push( obj )
			}

			if ( postData.length )
			{
				this.is_saving = true;
				// return this.$axios.put(restApi_stringsUrl, postData)
				return this.$apiPut(restApi_stringsUrl, postData)
								.then((param)=> { this.is_saving = false; return param; })
								.catch( (error) =>
								{
									console.error(error);
									this.is_saving = false;
								})
			}

			return Promise.resolve(null)
		},

		async saveAll(list)
		{
			consola.debug('saving...');
			return await this.asyncSaveAll(list)
		},
	},
}
