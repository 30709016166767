//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vClickOutside from 'v-click-outside'
import svgSymbol from '~/components/svg-symbol'


export default {
	directives: {
		clickOutside: vClickOutside.directive
	},

	components:
	{
		'svg-symbol': svgSymbol,
	},

	data()
	{
		return {
			isAnimating: false,
		}
	},

	methods:
	{
		onClose(event)
		{
			this.$emit('close');
		}
	},

}
