//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
	// name: 'rest-string-link',



	props:
	{
		to:
		{
			type: String,
			required: true
		},

		editable:
		{
			type: Boolean,
			default: false
		},

		strName:
		{
			type: String,
			required: true
		},

		tag:
		{
			type: String,
			default: 'span'
		},
	},

	data()
	{
		return {
		}
	},

	computed:
	{
		...mapGetters([ 'getString', 'getStringId', 'lang', 'langShort', 'allLanguages']),


	},

}
