//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters, mapMutations, mapActions } from 'vuex'
// import find from '../../app/utils/find'
import domel from './domel'
// const config = require("../../config/nuxt")

export default {
	// name: 'rest-string',

	components:
	{
		domel
	},

	props:
	{

		strName:
		{
			type: String,
			required: true
		},

		tag:
		{
			type: String,
			default: 'span'
		},
	},

	data()
	{
		return {
		}
	},

	computed:
	{
		...mapGetters(['isEditingPage', 'getString', 'getStringId', ]),
	},

}
