//
//
//
//
//
//
//
//
//
//


// from https://github.com/johndatserakis/vue-simple-context-menu

import Vue from 'vue'
import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)

export default {
    name: 'VueSimpleContextMenu',
    props: {
        elementId: {
            type: String,
            required: true
        },
        options: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            item: null,
            menuWidth: null,
            menuHeight: null
        }
    },
    methods: {
        showMenu (event, item) {
            this.item = item

            var menu = document.getElementById(this.elementId)
            if (!menu) {
                return
            }

            if (!this.menuWidth || !this.menuHeight) {
                menu.style.visibility = "hidden"
                menu.style.display = "block"
                this.menuWidth = menu.offsetWidth
                this.menuHeight = menu.offsetHeight
                menu.removeAttribute("style")
            }

            if ((this.menuWidth + event.clientX) >= window.innerWidth) {
                menu.style.left = (event.clientX - this.menuWidth + 2) + "px"
            } else {
                menu.style.left = (event.clientX - 2) + "px"
            }

            if ((this.menuHeight + event.clientY) >= window.innerHeight) {
                menu.style.top = (event.clientY - this.menuHeight + 2) + "px"
            } else {
                menu.style.top = (event.clientY - 2) + "px"
            }

            menu.classList.add('vue-simple-context-menu--active')
        },
        hideContextMenu () {
            let element = document.getElementById(this.elementId)
            if (element) {
                element.classList.remove('vue-simple-context-menu--active');
            }
        },
        onClickOutside () {
            this.hideContextMenu()
        },
        optionClicked (option) {
            this.hideContextMenu()
            this.$emit('option-clicked', {
                item: this.item,
                option: option
            })
        }
    }
}
