//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import find from '../../app/utils/find'
import domel from './domel'
// const config = require("../../config/nuxt")

const restApi_stringsUrl = 'string'

var _singletonCounter = 0;

export default {
	name: 'rest-string-link',

	components:
	{
		domel,
	},

	props:
	{
		to:
		{
			type: String,
			required: true
		},

		editable:
		{
			type: Boolean,
			default: false
		},

		strName:
		{
			type: String,
			required: true
		},

		tag:
		{
			type: String,
			default: 'span'
		},
	},

	data()
	{
		return {
			is_saving: false,
			hasFocus: false,
			is_dirty: false,
			// postData: Object.freeze([])
			postData: null,
			singletonCounter: 0,
		}
	},

	computed:
	{
		...mapGetters(['isEditingPage', 'getString', 'getStringId', 'lang', 'langShort', 'allLanguages']),

		isDirty()	{	return this.is_dirty	},
		isSaving()	{	return this.is_saving	},
		isEditable() { return this.isEditingPage || this.editable; },
		emptyStyle()
		{
			if (this.isEditable && !this.getString(this.strName))
				return { '--placeholder-text': `"<${this.strName}>"` }
			return {}
		}
	},

	watch:
	{
		hasFocus(newVal)
		{
			if ( newVal==false )
			{
				this.validate()
			}
		}
	},

	created()
	{
		this.singletonCounter = _singletonCounter++;
	},

	mounted()
	{
		// this.$eventBus.$on('rest:model', (data) => this.addUpdateData(data) )
	},

	methods:
	{
		onInput(event)
		{
			// consola.debug('onInput: ', event)
			this.is_dirty = true;
			this.$emit('input', event.target.value);
			// remove formating
			// event.target.innerHTML = event.target.innerText;
			// this.postData = Object.freeze( this.$refs['span'].innerText );
			this.$eventBus.$emit('rest:string', this)
		},

		validate()
		{
			consola.debug('validate')
			this.is_dirty = true;
			// if ( this.maxlength )
			// {
			// 	event.target.innerText = event.target.innerText.substring(0, this.maxlength)
			// }
			// if ( this.richtext == false )
			{
				// remove formating
				event.target.innerHTML = event.target.innerText;
				this.postData = Object.freeze( this.$refs['span'].innerText );

				const strId = this.getStringId(this.strName)
				if ( strId == -1 )
				{
					consola.debug(`string ERROR: could not find [${this.strName}]`)
				}
				else
				{
					const obj = {
									_type: 'string',
									locale: this.lang,
									id: strId,
									value: this.postData
								};

					this.$eventBus.$emit('modify:model', { obj: obj,  field: '' });
				}
			}
			// this.asyncCommit()
		},

		onChange(event)
		{
			// consola.debug('onChange: ', event)
		},

		clearAll(list)
		{
			list = list || [];

			for (let i = 0; i < list.length; i++)
			{
				list[i].is_dirty = false
				list[i].postData = null
			}
		},

		asyncSaveAll(list)
		{
			list = list || [];

			let postData = [];
			for (let i = 0; i < list.length; i++)
			{
				const item = list[i]
				const strId = this.getStringId(item.strName)
				if ( strId == -1 || item.postData == null)
					continue

				const obj = {
					locale: this.lang,
					id: strId,
					value: item.postData
				}
				postData.push( obj )
			}

			if ( postData.length )
			{
				this.is_saving = true;
				// return this.$axios.put(restApi_stringsUrl, postData)
				return this.$apiPut(restApi_stringsUrl, postData)
								.then((param)=> { this.is_saving = false; return param; })
								.catch( (error) =>
								{
									console.error(error);
									this.is_saving = false;
								})
			}

			return Promise.resolve(null)
		},

		async saveAll(list)
		{
			consola.debug('saving...');
			return await this.asyncSaveAll(list)
		},
	},
}
