//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


function hexToR(h) {return parseInt((cutHex(h)).substring(0,2),16)}
function hexToG(h) {return parseInt((cutHex(h)).substring(2,4),16)}
function hexToB(h) {return parseInt((cutHex(h)).substring(4,6),16)}
function cutHex(h) {return (h.charAt(0)=="#") ? h.substring(1,7):h}

export default {
	name: 'spinner-overlay',

	props:
	{
		bgColor:
		{
			type: String,
			default: '#FFFFFF'
		},

		bandColor: 
		{
			type: String,
			default: '#000000'
		}
	},

	computed:
	{
		bgRGB()
		{
			return { r: hexToR(this.bgColor), g: hexToG(this.bgColor), b: hexToB(this.bgColor) };
		},

		overlayStyle()
		{
			return {
				'background-color': `rgba(${this.bgRGB.r}, ${this.bgRGB.g}, ${this.bgRGB.b}, 0.6)`,
			}
		}
	}
}
