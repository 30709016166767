//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import tag from '~/components/tag'
// import vDropdown from 'v-dropdown'
import Dropdown from '~/components/dropdown'
// import Spinner from 'vue-simple-spinner'
import Spinner from '~/components/spinner'
import {FadeTransition} from 'vue2-transitions'


export default {
	name: 'tag-input',

	components:
	{
		tag,
		// 'v-dropdown': vDropdown,
		'v-dropdown': Dropdown,
		Spinner,
		FadeTransition,
	},

	props:
	{
		value:
		{
			type:[Array, Number],
			required: true,
		},

		syncRestModel:
		{
			type: Boolean,
			default: true,
		},

		maxcount:
		{
			type: Number,
			default: 0,
		},

		itemKey:
		{
			type: String,
			required: true,
		},

		datasource:
		{
			type: Array,
			required: true,
		},

		datakey:
		{
			type: String,
			required: true,
		},

		dataValueField:
		{
			type: String,
			required: true,
		},

		url:
		{
			type: String,
			default: 'taxonomy'
		},

		field:
		{
			type: String,
			required: true
		},

		parentKey:
		{
			type: String,
			required: true,
		},
	},

	data()
	{
		return {
			// is_dirty: false,
			showSpinner: false,
			showList: true,
			showCreate: false,
			showEdit: false,
			selectedItem: null,
			model:
			{
				createID: '',
				createLabel: '',
				updateLabel: '',
				errorID: null,
				errorLabel: null,
			}

		}
	},

	computed:
	{
		inputList()
		{
			if ( Array.isArray(this.value) )	return this.value;
			else	return (this.value===null ? [] : [this.value]);
		}
	},

	mounted()
	{
		// this.$on('tag:remove', this.onTagRemove)
		// consola.debug('tag-input model: ', this.value)
		// consola.debug('tag-input datasource: ', this.datasource)
	},

	methods:
	{
		gotoPanel(panel, selectedItem = null)
		{
			consola.debug('tag-input gotoPanel: ', panel);
			switch (panel)
			{
				default:
				case 0:
					this.showList = true
					this.selectedItem = null
					this.model.errorID = null;
					this.model.errorLabel = null;
					break;

				case 1:
					this.model.createID = '';
					this.model.createLabel = '';
					this.showList = false
					this.selectedItem = null
					break;

				case 2:
					if (this.$refs.dropdown.show==false)
						this.$refs.dropdown.visible()
					this.showList = false
					this.model.updateLabel = selectedItem[this.dataValueField]
					this.selectedItem = selectedItem
					break;
			}
		},

		onDropdown(event)
		{
			consola.debug('onDropdown: ', event)
			if (event)
			{
				this.$refs.dropdown.adjust()
			}
			else
			{
				this.showList = true;
			}
		},

		sendDataToAncestor( data, funcName )
		{
			if (!this.syncRestModel)	return;

			consola.debug('sendDataToAncestor: ', data)
			let parent = this.$parent
			// let id = -1
			while ( parent )
			{
				// consola.debug('_componentTag: ', parent.$options._componentTag)
				if (parent.$options._componentTag == 'nuxt')
					break;
				if (parent.$options._componentTag == 'rest-model')
				{
					if (funcName)
						parent[funcName]( data )
					else
						parent.addUpdateData( data )
					break
				}

				if (parent == parent.$parent)
					break;

				parent = parent.$parent
			}
		},

		onTagRemove({ item, index })
		{
			consola.debug('onTagRemove ', item, index)
			if ( Array.isArray(this.value) )
			{
				let list = [...this.value];
				list.splice(index, 1);
				this.sendDataToAncestor( { [this.field]: JSON.stringify(list.map(x => x[this.itemKey])) } )
				// this.is_dirty = true;
				this.$emit('input', list);
			}
			else
			{
				this.$emit('input', null);
			}
		},

		onItemSelect(item, index)
		{
			debugger;
			this.$emit('tag:select', {item, index});
			let list = [...this.value, { ...item, taxonomy_id: item.id }];
			this.sendDataToAncestor( { [this.field]: JSON.stringify(list.map(x => x[this.itemKey])) } )
			// this.is_dirty = true;
			this.$emit('input', list);
			this.$refs.dropdown.show = false;
		},

		onCreate(event)
		{
			var hasErrors = false;
			this.model.errorID = null;
			this.model.errorLabel = null;

			if (this.model.createID.trim().length == 0)
			{
				this.model.errorID = true;
				hasErrors = true;
			}
			if (this.model.createLabel.trim().length == 0)
			{
				this.model.errorLabel = true;
				hasErrors = true;
			}

			if (hasErrors)
				return;

			this.showSpinner = true;

			this.$apiPost(this.url, {
				parent_key: this.parentKey,
				key: this.model.createID,
				value: this.model.createLabel,
			})
			.then( (resp)=>
			{
				consola.debug('Taxonomy create resp: ', resp);
				// let list = [...this.value, { ...resp.data }];
				this.$emit('update:datasource', { ...resp.data } );
			} )
			.catch( (error)=>
			{
				consola.debug('Taxonomy create error: ', error)
			} )
			.finally( ()=>
			{
				this.showSpinner = false;
				this.gotoPanel(0);
			})
		},

		onUpdate(event)
		{
			var hasErrors = false;
			this.model.errorID = null;
			this.model.errorLabel = null;

			if (this.model.updateLabel.trim().length == 0)
			{
				this.model.errorLabel = true;
				return;
			}

			if (hasErrors)
				return;

			this.showSpinner = true;
			consola.debug('update: ', this.selectedItem)

			const data = {
				...this.selectedItem,
				// id: this.selectedItem.id,
				parent_key: this.parentKey,
				// key: this.model.createID,
				value: this.model.updateLabel,
			}

			consola.debug('tag-input: updating ', data);
			debugger;

			this.$apiPut(this.url, data)
			.then( (resp)=>
			{
				consola.debug('Taxonomy update resp: ', resp);
				// let list = [...this.value, { ...resp.data }];
				this.updateModelItem({ ...data })
				this.$emit('update:datasource', { ...data } );

			} )
			.catch( (error)=>
			{
				consola.debug('Taxonomy update error: ', error)
			} )
			.finally( ()=>
			{
				this.showSpinner = false;
				this.gotoPanel(0);
			})
		},

		updateModelItem(item)
		{
			for (var i = 0; i < this.value.length; i++)
			{
				const obj = this.value[i];

				if (obj[this.itemKey] == item[this.datakey])
				{
					consola.debug('updateModelItem: ', item)
					obj.value = item.value;
					let list = [...this.value];
					this.$emit('input', list);
					return;
				}
			}
		},

		exists(item)
		{
			for (var i = 0; i < this.value.length; i++)
			{
				const obj = this.value[i];

				if (obj[this.itemKey] == item[this.datakey])
				{
					// consola.debug('exists equality: ', obj, item);
					return true;
				}
			}

			return false;
		}
	}


}
