//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
	name: 'rest-prop',
	props:
	{
		maxlength:
		{
			type: Number,
			default: 0
		},

		value:
		{
			required: true,
		},

		richtext:
		{
			type: Boolean,
			default: false
		},

		editable:
		{
			type: Boolean,
			default: false
		},

		tag:
		{
			type: String,
			default: 'span'
		},

		url:
		{
			type: String,
			default: ''
		},

		field:
		{
			type: String,
			default: ''
		},

		saveHtml:
		{
			type: Boolean,
			default: false
		},

		object:
		{
			type: Object,
			required: true,
		},

		// the (locale-specific) object "id", not the parent id
		modelId:
		{
			type: Number,
			default: -1,
		}
	},

	data()
	{
		return {
			hasFocus: false,
			is_dirty: false,
			postData: Object.freeze({}),
			content: ''
		}
	},

	computed:
	{
		...mapGetters(['isEditingPage']),
		isDirty()	{	return this.is_dirty	},
		isEditable() { return this.isEditingPage || this.editable; },
		isSlotEmpty() {	return !this.content },
		// isSlotEmpty() {	return !this.$slots.default || !this.$slots.default.innerText },
		emptyStyle()
		{
			if (this.isEditable && this.field && this.isSlotEmpty)
				return { '--placeholder-text': `"<${this.field}>"` }
			return {}
		}
	},

	watch:
	{
		hasFocus(newVal)
		{
			if ( newVal==false )
			{
				this.validate()
			}
		},

		value(newVal)
		{
			if ( !this.isEditable )	return;
			this.content = newVal;
			setTimeout( ()=>
			{
				this.$refs['tag'].innerHTML = newVal;
			}, 0 )
		}
	},

	created()
	{
		this.content = this.value;
	},

	render(createElement)
	{
		let attrs = {};
		if (this.isEditable)
			attrs.contenteditable = this.isEditable;

		return createElement(
			this.tag,
			{
				ref: 'tag',

				// attrs:
				// {
				// 	contenteditable: this.isEditable,
				// },
				attrs,

				class:
				{
					'EditableText': true,
					'rest-placeholder': true,
					'is-dirty': this.isDirty,
					'has-focus': this.hasFocus,
				},

				style: this.emptyStyle,

				on:
				{
					input: this.onInput,
					change: this.onChange,
					focus: () => this.hasFocus = true,
					blur: () => this.hasFocus = false,
				}
			},
			this.content
			// this.$slots.default
			// // this.$slots.default ?
			// // [
			// // 	...this.$slots.default,
			// // 	createElement('div', { class: 'rest-edit-icon' })
			// // ]
			// // : this.$slots.default
		)
	},

	methods:
	{
		onInput(event)
		{
			// consola.debug('onInput: ', event)
			this.is_dirty = true;
			// this.$emit('input', event.target.value);
			// this.content = event.target.innerText;
			// // this.$emit('input', this.content);
			// this.$emit('update:value', this.content); // use with .sync
			// if ( this.maxlength )
			// {
			// 	event.target.innerText = event.target.innerText.substring(0, this.maxlength)
			// }
			// if ( this.richtext == false )
			// {
			// 	// remove formating
			// 	event.target.innerHTML = event.target.innerText;
			// }
			// this.asyncCommit()
		},

		validate()
		{
			consola.debug('validate')
			this.is_dirty = true;
			if ( this.maxlength )
			{
				event.target.innerText = event.target.innerText.substring(0, this.maxlength)
			}
			if ( this.richtext == false )
			{
				// remove formating
				event.target.innerHTML = event.target.innerText;
			}
			this.asyncCommit()
			this.onChange();
		},

		onChange(event)
		{
			consola.debug('onChange: ', event)
			// this.content = event.target.innerText;
			this.content = this.$refs['tag'].innerText || this.$refs['tag'].innerHTML;

			this.$emit('input', this.content);
			this.$eventBus.$emit('modify:model', { obj: this.object,  field: this.field });
			// this.$emit('update:value', this.content);
		},

		addUpdateData(obj)
		{
			let data = {...this.postData, ...obj};
			this.postData = Object.freeze(data);
		},

		calcUpdateData()
		{
			let obj = {};
			if (this.field.length > 0)
				obj[this.field] = this.saveHtml ? this.$refs['tag'].innerHTML : this.$refs['tag'].innerText;

			let data = {...this.postData, ...obj};
			this.postData = Object.freeze(data);

			return this.postData;
		},


		sendDataToAncestor()
		{
			let parent = this.$parent
			// let id = -1
			while ( parent )
			{
				// consola.debug('_componentTag: ', parent.$options._componentTag)
				if (parent.$options._componentTag == 'nuxt')
					break;
				if (parent.$options._componentTag == 'rest-model')
				{
					parent.addUpdateData( { ...this.postData } )
					break
				}

				if (parent == parent.$parent)
					break;

				parent = parent.$parent
			}
		},



		asyncCommit()
		{
			if (this.is_dirty)
			{
				if (this.field.length > 0)
				{
					this.calcUpdateData()
					// debugger

					let emitEvent = false

					if (this.modelId > -1)
					{
						this.addUpdateData( { id: this.modelId } )

						if (this.url.length > 0)
						{
							// return this.$axios.put(this.url, this.postData)
							return this.$apiPut(this.url, this.postData)
											.then((param)=> { this.is_dirty = false; return param; })
											.catch( (error) =>
											{
												console.error(error);
											})
						}
						else
						{
							emitEvent = true
							// console.warn('editable-text has NO REST url...')
						}
					}
					else
					{
						emitEvent = true
						// console.warn('editable-text has NO model ID...')
					}

					if (emitEvent)
					{
						// NOTE: emiting events on an event-bus
						// causes *ALL* listeners to respond, regardless
						// of their position in the hierarchy.
						// best avoid that approach

						// consola.debug('emiting event...')
						// let data = { ...this.postData }
						// this.$eventBus.$emit('rest:model', data)
						// this.is_dirty = false

						this.sendDataToAncestor()
					}

				}
				else
					console.warn('rest-prop has NO field...')
			}
			else
			{
				console.warn('rest-prop is NOT dirty...')
			}

			return Promise.resolve(null)

			// if (this.is_dirty == false || this.url.length == 0 || this.field.length == 0)
			// {
			// 	return Promise.resolve(null)
			// }
			// else
			// {
			// 	let obj = {};
			// 	obj[this.field] = this.$refs.innerText;
			// 	this.is_dirty = false;
			// 	return this.$axios.post(this.url, obj)
			// }
		},

		async commit()
		{
			consola.debug('saving...');
			return await this.asyncCommit()
		},
	},

}
