//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Modal from './modal-container'
import SmoothScrollbar from '~/lib/smooth-scrollbar-gsap/index.js';
import RestImg from '~/components/rest-img-dummy';
const parseUrl = require('url-parse');

export default {
	components:
	{
		Modal,
		RestImg,
	},


	props:
	{
		url:
		{
			type: [String, Object],
			required: true,
		},


	},

	data()
	{
		return {
			loading: true,
			block: null,
			modalReady: false,
		};
	},

	computed:
	{
		...mapGetters(['isEditingPage', 'getString', 'lang', 'langShort','windowWidth', 'isMobile', 'isTablet', 'isTabletOrBigger', 'isLargerThanTabletLandscape', 'isLaptop', 'isLaptopOrBigger', 'isDesktop', 'windowWidth', 'scrollTop']),

		modalContentClasses()
		{
			return {
				'modal-content--internal': !this.isExternal,
				'modal-content--hidden': !this.modalReady,
			}
		},

		isExternal()
		{
			return typeof this.url=='string';
		},

		isInstructor()
		{
			return typeof this.url!='string' && this.url.instructor_id;
		},

		title()
		{
			if (this.loading)	return '';
			return this.block.title || this.block.name;
		},
	},

	watch:
	{
		loading(newVal)
		{
			if (!newVal && this.block && window.innerWidth > 768)
			{
				this.$nextTick( () =>
				{
					if ( !this.$refs['text-container'] )
					{
						consola.debug('could not find ref of "text-container"')
						return;
					}

					this.initSmoothScroll();

				} )
			}

			if ( !this.isInstructor )
				this.modalReady = true;
			else
				setTimeout(()=>
				{
					this.modalReady = true;
				}, 200)
		}
	},

	beforeMount()
	{
		if (this.url && typeof this.url!='string')
		{
			// const self = this;
			// setTimeout(() =>
			// {
			// 	self.block = self.url;
			// 	self.loading = false;
			// }, 10)
			return;
		}

		let url = parseUrl( this.url );
		let path = url.pathname;
		let parts = path.split('/').filter(x => !!x); // remove empty parts
		let hasLocale = (parts.length > 0 && parts[0].length==2)
		let locale = hasLocale ? parts.shift() : this.lang;
		path = '/' + parts.join('/');
		this.$apiGet('page-slug/', { slug: path, locale })
			.then( resp =>
			{
				if (resp.data[0].block_list.length > 0)
				{
					this.block = resp.data[0].block_list[0];
				}
				else
				{
					consola.debug('loaded page was empty...');
				}
				this.loading = false;
			} )
			.catch( error =>
			{
				consola.debug('modal-loader error: ', error);
				this.loading = false;
			})
	},

	mounted()
	{
		if (process.client)
		{
			this.$freezeBodyScroll(true)
		}

		if (this.url && typeof this.url!='string')
		{
			const self = this;
			self.block = self.url;
			self.loading = false;
			// setTimeout(() =>
			// {
			// 	self.block = self.url;
			// 	self.loading = false;
			// }, 10)
			// return;
		}

		consola.debug(`mounted: loading[${this.loading}] block: `, this.block)
		consola.debug(`mounted: loading[${this.loading}] url: `, this.url)
	},

	beforeDestroy()
	{
		if (process.client)
		{
			this.$freezeBodyScroll(false)
		}
	},

	methods:
	{
		onClose()
		{
			this.destroySmoothScroll();
			this.$emit('close');
		},

		initSmoothScroll()
		{
			consola.debug('Scrollbar init')
			// debugger;
			let pageContent = this.$refs['text-container'];
			if (!pageContent)	return;
			this.smoothScrollbar = SmoothScrollbar.init(pageContent, {
													renderByPixels: false,
													// skewScale: window._disableSkew ? 0 : 0.01 * 0.5,
													// skewScale: 0.01 * 0.5,
													rafLoop: this.$raf,
													// tracksParent: document.body,
													// alwaysShowTracks: false,
													alwaysShowTracks: true,
												});
			// document.documentElement.classList.add('smooth-scroll');
			// consola.debug('scrollbar', this.smoothScrollbar);
		},

		destroySmoothScroll()
		{
			if (this.smoothScrollbar)
			{
				// let scrollbars = document.querySelectorAll('body > .scrollbar-track');
				// if (scrollbars)
				// {
				// 	for (let i = 0; i < scrollbars.length; i++)
				// 	{
				// 		document.body.removeChild(scrollbars[i]);
				// 	}
				// }
				this.smoothScrollbar.destroy();
			}
			this.smoothScrollbar = null;
		},

	}


}
