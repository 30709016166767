//
//
//
//
//
//
//
//
//
//
//

import Youtube from '~/components/youtube-embed'
import getYouTubeID from 'get-youtube-id';

export default {

	inheritAttrs: false,

	components:
	{
		Youtube,
	},

	props:
	{
		alt:
		{
			default: '',
		},

		srcset:
		{
			type: Array,
			default: () => []
		},

		mediaset:
		{
			type: Array,
			default: () => []
		},

		// src:
		value:
		{
			type: String,
			default: ''
		},

		// src:
		mobile:
		{
			type: String,
			default: null
		},
	},

	computed:
	{
		// webp()
		// {
		// 	if (!this.value)	return '';
		// 	return this.value.split('.').slice(0, -1).join('.') + '.webp';
		// },

		imgUrl()
		{
			if (this.value)	return this.value;
			if (this.srcset.length) return this.srcset[0].split(' ')[0];
			return '';
		},

		isYoutube()
		{
			return !!this.videoId;
			// return (this.imgUrl.indexOf('https://www.youtube.com/watch?v=') > -1);
		},

		videoId()
		{
			return getYouTubeID(this.imgUrl);
			// return this.imgUrl.split('&').pop().replace('https://www.youtube.com/watch?v=', '');
		},

		imgSrcSet()
		{
			if (!this.mobile)
			{
				return this.value;
			}
			else
			{
				return `${this.value}, ${this.mobile} 560w`
			}
		},


		webp()
		{
			if (!this.value && !this.mobile)	return '';
			if (this.value && this.mobile)
			{
				return `${this.value.split('.').slice(0, -1).join('.') + '.webp'}, ${this.mobile.split('.').slice(0, -1).join('.') + '.webp'} 560w`
			}
			if (this.value && !this.mobile)
				return this.value.split('.').slice(0, -1).join('.') + '.webp';
			if (!this.value && this.mobile)
				return this.mobile.split('.').slice(0, -1).join('.') + '.webp';
		},
	}
}
