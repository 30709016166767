//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import includes from 'lodash/includes'
import { mapGetters, mapMutations, mapActions } from 'vuex'
// import { Promise } from 'q';
// import Youtube from '~/components/youtube-view'
import Youtube from '~/components/youtube-embed'
import getYouTubeID from 'get-youtube-id';

var _singletonCounter = 0;

export default {
	name: 'rest-img',

	inheritAttrs: false,

	components:
	{
		Youtube,
	},

	props:
	{
		updateModel:
		{
			type: Boolean,
			default: true,
		},

		debugStr: {
			type: String,
			default: ''
		},

		editable:
		{
			type: Boolean,
			default: false
		},

		alt:
		{
			type: String,
			default: '',
		},

		allLocales:
		{
			type: Boolean,
			default: false
		},

		// tag:
		// {
		// 	type: String,
		// 	default: 'span'
		// },

		srcset:
		{
			type: Array,
			default: () => []
		},

		mediaset:
		{
			type: Array,
			default: () => []
		},

		object:
		{
			type: Object,
			default: null,
			required: true,
		},

		// src:
		value:
		{
			type: String,
			default: ''
		},

		mobile:
		{
			type: String,
			default: ''
		},

		url: // this is the DB Model rest endpoint, not the url of the img
		{
			type: String,
			default: ''
		},

		field: // this is the field of the DB Model to store the img url
		{
			type: String,
			default: ''
		},

		// the (locale-specific) object "id", not the parent id
		modelId:
		{
			type: Number,
			default: -1,
		}
	},

	data()
	{
		return {
			singletonCounter: 0,
			is_dirty: false,
			postData: Object.freeze({}),

			isDragOver: false,
			isUploading: false,
			uploadProgress: 0,
			previewImgDataUrl: null,

			uploadPromise: Promise.resolve(true),
			uploadedPath: null,
			isUploadMobile: false,

			dragenter: null,
			dragover: null,
			dragleave: null,
			drop: null,
			click: null,
		}
	},

	computed:
	{
		...mapGetters(['isEditingPage']),
		isDirty()	{	return this.is_dirty	},
		isEditable() { return this.isEditingPage || this.editable; },

		// DONE: Need to fix the updating issue

		isYoutube()
		{
			return !!this.videoId;
			// return (this.imgUrl.indexOf('https://www.youtube.com/watch?v=') > -1);
		},

		videoId()
		{
			return getYouTubeID(this.imgUrl);
			// return this.imgUrl.split('&').pop().replace('https://www.youtube.com/watch?v=', '');
		},

		imgList()
		{
			if (/*this.isEditingPage &&*/ this.previewImgDataUrl)
			{
				return [this.previewImgDataUrl]
			}
			else
			{
				if (this.srcset.length)	return this.srcset;
				else					return [this.value];
			}
		},

		imgUrl()
		{
			if (/*this.isEditingPage &&*/ this.previewImgDataUrl) return this.previewImgDataUrl;
			if (this.value)	return this.value;
			if (this.srcset.length) return this.srcset[0].split(' ')[0];
			return '';
		},


		imgSrcSet()
		{
			if (!this.mobile)
			{
				return this.imgUrl;
			}
			else
			{
				return `${this.imgUrl}, ${this.mobile} 600w`
			}
		},


		webp()
		{
			if (!this.imgUrl && !this.mobile)	return '';
			if (this.imgUrl && this.mobile)
			{
				return `${this.imgUrl.split('.').slice(0, -1).join('.') + '.webp'}, ${this.mobile.split('.').slice(0, -1).join('.') + '.webp'} 560w`
			}
			if (this.imgUrl && !this.mobile)
				return this.imgUrl.split('.').slice(0, -1).join('.') + '.webp';
			if (!this.imgUrl && this.mobile)
				return this.mobile.split('.').slice(0, -1).join('.') + '.webp';
		},

		imgStyle()
		{
			if ( this.imgUrl )
			{
				return {
					'background-image': 'none'
				}
			}
			else
			{
				return {};
			}
		}

	},

	watch:
	{
		srcset(newVal)
		{
			this.previewImgDataUrl = null;
		},

		mediaset(newVal)
		{
			this.previewImgDataUrl = null;
		},

		value(newVal)
		{
			this.previewImgDataUrl = null;
		},

		isEditingPage(newVal)
		{
			if (newVal)
				this.$nextTick( ()=> this.registerEvents() )
			else
				this.unregisterEvents();
		}
	},

	beforeCreate()
	{
	},

	created()
	{
		this.singletonCounter = _singletonCounter++;

		// consola.debug('rest-img: value=', this.$props.value)
		// this.$store.commit('addSeoPageEntry', this.$props.value);

		this.dragenter = this.onDragEnter.bind(this)
		this.dragover = function(e)
		{
			e.preventDefault();
			// e.stopPropagation();
			e.dataTransfer.dropEffect = "move";
			return false;
		};
		this.dragleave = this.onDragLeave.bind(this)
		this.drop = this.onDrop.bind(this)
		this.click = this.selectFromLibrary.bind(this)
	},

	mounted()
	{


		if ( this.isEditingPage || this.editable)
		{
			// this.$nextTick( ()=> this.registerEvents() )
			const self = this;
			setTimeout( ()=> self.registerEvents(), 100 )
		}
	},

	methods:
	{
		getMedia(index)
		{
			if (this.mediaset && this.mediaset[index])	return this.mediaset[index];
			return false;
		},

		registerEvents()
		{
			// no longer needed
			return;

			consola.debug('registering rest-img events...');
			this.$refs['pic'].addEventListener("dragenter", this.dragenter)
			this.$refs['pic'].addEventListener("dragover", this.dragover)
			this.$refs['pic'].addEventListener("dragleave", this.dragleave)
			this.$refs['pic'].addEventListener("drop", this.drop)
			this.$refs['pic'].addEventListener("click", this.click)
		},

		unregisterEvents()
		{
			// no longer needed
			return;

			consola.debug('unregistering rest-img events...');
			this.$refs['pic'].removeEventListener("dragenter", this.dragenter)
			this.$refs['pic'].removeEventListener("dragover", this.dragover)
			this.$refs['pic'].removeEventListener("dragleave", this.dragleave)
			this.$refs['pic'].removeEventListener("drop", this.drop)
			this.$refs['pic'].removeEventListener("click", this.click)
		},

		selectFromLibrary(event)
		{
			consola.debug('selectFromLibrary')
			event.preventDefault();
			event.stopPropagation();

			this.$eventBus.$emit('media:open', {url: 'media'})
			this.$eventBus.$once('media:selected', (obj)=>
			{
				if ( !obj )
					return;

				consola.debug('img path: ', obj.item && obj.item.name)
				this.is_dirty = true;
				if ( !obj.item )
					this.uploadedPath = '';
				else
					this.uploadedPath = '/media/' + obj.item.name;
				this.$emit('input', this.uploadedPath);
				this.previewImgDataUrl = this.uploadedPath;
				this.registerChange()
			})
		},

		onDragEnter(event)
		{
			consola.debug('onDragEnter', event.dataTransfer.types);
			consola.debug('onDragEnter:', event);
			// consola.debug('onDragEnter files', event.dataTransfer.files);
			const types = [...event.dataTransfer.types];
			// consola.debug('types: ', types);
			if ( types.includes('Files') ||
				types.includes("text/uri-list") )
			{
				consola.debug('onDragEnter: acceptable item');
				event.preventDefault();
				// event.stopPropagation();
				event.dataTransfer.dropEffect = "move";

				this.isDragOver = true;
				return false;
			}
		},

		onDragLeave(event)
		{
			consola.debug('onDragLeave', event);
			event.preventDefault();
			event.stopPropagation();
			this.isDragOver = false;
		},

		onDrop(event)
		{
			consola.debug('onDrop', event);
			consola.debug('onDrop types', event.dataTransfer.types);
			// consola.debug('onDrop filelist', event.dataTransfer.files);
			event.preventDefault();
			// event.stopPropagation();
			event.dataTransfer.dropEffect = "move";

			const types = [...event.dataTransfer.types];
			if ( types.includes("text/uri-list") )
			{
				const url = event.dataTransfer.getData("text/uri-list");
				consola.debug('urls: ', url);
				if (url.indexOf('https://www.youtube.com/watch?v=') > -1)
				{
					this.uploadedPath = url;
					this.registerChange();
					this.isDragOver = false;
					return false;
				}
			}

			for (let i = 0; i < event.dataTransfer.files.length; i++)
			{
				const file = event.dataTransfer.files[i];
				consola.debug('file type: ', file.type);
				if ( file.type.indexOf('image') > -1 )
				{
					const data = event.dataTransfer.getData(file.type);
					consola.debug('onDrop data: ', typeof data);
					this.uploadImage(file);
					break;
				}
			}
			this.isDragOver = false;
		},



		clear()
		{
			consola.debug('rest-img: clear()')
			this.is_dirty = false;
			this.previewImgDataUrl = null;
			this.uploadPromise = null;
			this.isUploading = false;
			this.uploadedPath = null;

			this.$nextTick( () => this.$forceUpdate() )

		},


		uploadImage(file)
		{
			if (!file)	Promise.reject('no file given');

			this.is_dirty = true;

			let formData = new FormData()

			formData.append('imgfile', file)

			this.isUploading = true;

			let reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onloadend = () => this.previewImgDataUrl = reader.result

			this.uploadPromise = this.$apiPost('upload', formData, {
				onUploadProgress: (progressEvent) =>
				{
					// consola.debug(progressEvent)
					this.uploadProgress = (progressEvent.loaded / progressEvent.total) * 100;
				}
			});

			this.uploadPromise
				.then( (resp) =>
				{
					this.isUploading = false;
					consola.debug('image upload response: ', resp.data)
					if (resp.data.error)
					{
						consola.debug(resp.data.error.message || 'Upload error');
						this.$emit('alert', {msg: resp.data.error.message || 'Upload error', type: 'error' })
					}
					if (resp.data.path)
					{
						this.uploadedPath = resp.data.path;
						// this.asyncCommit()
						this.registerChange()
					}
					this.uploadProgress = 0;
				} )
				.catch( (error) =>
				{
					this.clear()
					consola.debug('image upload error: ', error)
				})

			this.$eventBus.$emit('rest:image:upload:begin', this);
		},



		//-----------------------------------------------------



		addUpdateData(obj)
		{
			let data = {...this.postData, ...obj};
			this.postData = Object.freeze(data);
		},

		calcUpdateData()
		{
			let obj = {};
			if (this.field.length > 0 && this.uploadedPath)
				obj[this.field] = this.uploadedPath;

			if (this.allLocales)
				obj['_allLocales'] = true;

			let data = {...this.postData, ...obj};
			this.postData = Object.freeze(data);

			return this.postData;
		},

		registerChange()
		{
			// debugger
			if (this.$listeners['update:mobile'])
				this.isUploadMobile = window.confirm('Mobile image?');
			else
				this.isUploadMobile = false;

			if (this.isUploadMobile)
			{
				this.$emit('update:mobile', this.uploadedPath);
				this.previewImgDataUrl = null;
			}
			else
				this.$emit('input', this.uploadedPath);

			if (this.object)
			{
				// this.$eventBus.$emit('update:image-locales', this.object);
				this.$eventBus.$emit('modify:model', { obj: this.object,  field: this.field });
			}

			if (this.updateModel == false)	return;

			this.is_dirty = true;
			if (this.field.length > 0)
			{
				this.calcUpdateData()
				// debugger

				let emitEvent = false

				// debugger;

				if (this.modelId > -1)
				{
					this.addUpdateData( { id: this.modelId } )

					if (this.url.length > 0)
					{
						// debugger;
						// return this.$axios.put(this.url, this.postData)
						this.$eventBus.$emit('rest:model', this);
						return;
					}
				}

				this.sendDataToAncestor()
			}
			else
				console.warn('rest-date has NO field...')
			// if (this.is_dirty)
			// {
			// }
			// else
			// {
			// 	console.warn('rest-date is NOT dirty...')
			// }
		},


		sendDataToAncestor()
		{
			if (this.updateModel == false)	return;

			let parent = this.$parent
			// let id = -1
			while ( parent )
			{
				// consola.debug('_componentTag: ', parent.$options._componentTag)
				if (parent.$options._componentTag == 'nuxt')
					break;
				if (parent.$options._componentTag == 'rest-model')
				{
					parent.addUpdateData( { ...this.postData } )
					break
				}

				if (parent == parent.$parent)
					break;

				parent = parent.$parent
			}
		},



		asyncCommit()
		{
			if (this.updateModel == false)	return Promise.resolve(null);

			if (this.is_dirty)
			{
				if (this.field.length > 0)
				{
					this.calcUpdateData()
					// debugger

					let emitEvent = false

					if (this.modelId > -1)
					{
						this.addUpdateData( { id: this.modelId } )

						if (this.url.length > 0)
						{
							// return this.$axios.put(this.url, this.postData)
							return this.$apiPut(this.url, this.postData)
											.then((param)=> { this.is_dirty = false; return param; })
											.catch( (error) =>
											{
												console.error(error);
												return error;
											})
						}
						else
						{
							emitEvent = true
							// console.warn('editable-text has NO REST url...')
						}
					}
					else
					{
						emitEvent = true
						// console.warn('editable-text has NO model ID...')
					}

					if (emitEvent)
					{
						// NOTE: emiting events on an event-bus
						// causes *ALL* listeners to respond, regardless
						// of their position in the hierarchy.
						// best avoid that approach

						// consola.debug('emiting event...')
						// let data = { ...this.postData }
						// this.$eventBus.$emit('rest:model', data)
						// this.is_dirty = false

						this.sendDataToAncestor()
					}

				}
				else
					console.warn('rest-img has NO field...')
			}
			else
			{
				console.warn('rest-img is NOT dirty...')
			}

			return Promise.resolve(null)

			// if (this.is_dirty == false || this.url.length == 0 || this.field.length == 0)
			// {
			// 	return Promise.resolve(null)
			// }
			// else
			// {
			// 	let obj = {};
			// 	obj[this.field] = this.$refs.innerText;
			// 	this.is_dirty = false;
			// 	return this.$axios.post(this.url, obj)
			// }
		},

		async commit()
		{
			consola.debug('saving...');
			return await this.asyncCommit()
		},
	},

}
