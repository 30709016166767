//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;

export default {
	props:
	{
		debugStr: {
			type: String,
			default: ''
		},

		url:
		{
			type: String,
			required: true,
		},

		value:
		{
			type: Object,
			required: true,
		},
	},

	data ()
	{
		return {
			isProcessing: false,
		}
	},

	methods:
	{
		onSave()
		{
			consola.debug('onSave...')
			this.isProcessing = true;
			const data = this.value;
			consola.debug('sending data: ', data)
			// debugger;
			this.$apiPost(this.url, data)
			.then( (resp) =>
			{
				consola.debug(`create ${this.url} success: `, resp);

				this.$parent.$emit('resolve', resp.data[0])
			} )
			.catch( (error) =>
			{
				consola.debug(`create ${this.url} error: `, error);
				this.$parent.$emit('reject', error)
			} )
			.finally( ()=>
			{
				this.isProcessing = false;
			})
		}
	}



}
