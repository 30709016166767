//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
// import { mapGetters, mapMutations, mapActions } from 'vuex'
import find from '../../app/utils/find'

var _singletonCounter = 0;

export default {
	// name: 'rest-model',

	props:
	{
		debugStr: {
			type: String,
			default: ''
		},

		editable:
		{
			type: Boolean,
			default: false
		},

		url:
		{
			type: String,
			default: ''
		},

		field:
		{
			type: String,
			default: ''
		},

		object:
		{
			type: Object,
		},

		// the (locale-specific) object "id", not the parent id
		modelId:
		{
			type: Number,
			default: -1,
		},

		tag:
		{
			type: String,
			default: 'span'
		},

		allLocales:
		{
			type: Boolean,
			default: false,
		}
	},

	data()
	{
		return {
			is_saving: false,
			// postData: Object.freeze([])
			postData: [],
			createData: [],
			deleteData: [],
			singletonCounter: 0,
		}
	},

	computed:
	{
		// ...mapGetters(['isEditingPage']),

		isSaving()	{	return this.is_saving	}
	},

	created()
	{
		this.singletonCounter = _singletonCounter++;
	},

	mounted()
	{
		// this.$eventBus.$on('rest:model', (data) => this.addUpdateData(data) )
	},

	render(createElement)
	{
		// return this.$slots.default;
		return createElement(
			this.tag,
			{

			},
			this.$slots.default
		)
	},

	methods:
	{
		addUpdateData(obj)
		{
			// consola.debug(`[${this.singletonCounter}]addUpdateData: `, obj)

			let foundIdx = -1
			let sendUpwards = false;

			// if (this.debugStr)
			// 	debugger;

			if (typeof obj.id == 'undefined' || obj.id == -1)
			{
				if (this.modelId == -1)
				{
					sendUpwards = true;
				}
				else
				{
					obj.id = this.modelId
				}
			}

			if (typeof obj._$modelUrl == 'undefined' || !obj._$modelUrl)
			{
				if (!this.url)
				{
					sendUpwards = true;
				}
				else
				{
					obj._$modelUrl = this.url
				}
			}

			if (sendUpwards || obj._$modelUrl != this.url)
			{
				// this.$eventBus.$emit('rest:model', obj)
				this.sendDataToAncestor(obj)
			}
			else
			{
				// exclude '_$modelUrl' from the obj properties
				const { _$modelUrl, ...data } = obj;

				foundIdx = find(this.postData, 'id', obj.id)
				if (foundIdx > -1)
				{
					this.postData[foundIdx] = Object.freeze( { ...this.postData[foundIdx], ...data } )
				}
				else
				{
					this.postData.push( Object.freeze( {...data} ) );
				}

				this.$eventBus.$emit('rest:model', this)

				// consola.debug(`[${this.singletonCounter}]rest data `, JSON.stringify( this.postData ) )
			}

			// let data = {...this.postData, ...obj};
			// this.postData = Object.freeze(data);
		},

		addCreateData(obj)
		{
			// consola.debug(`[${this.singletonCounter}]addUpdateData: `, obj)

			let foundIdx = -1
			let sendUpwards = false;

			// if (typeof obj.id == 'undefined' || obj.id == -1)
			// {
			// 	if (this.modelId == -1)
			// 	{
			// 		sendUpwards = true;
			// 	}
			// 	else
			// 	{
			// 		obj.id = this.modelId
			// 	}
			// }

			if (typeof obj._$modelUrl == 'undefined' || !obj._$modelUrl)
			{
				if (!this.url)
				{
					sendUpwards = true;
				}
				else
				{
					obj._$modelUrl = this.url
				}
			}

			if (sendUpwards || obj._$modelUrl != this.url)
			{
				// this.$eventBus.$emit('rest:model', obj)
				this.sendDataToAncestor(obj, 'addCreateData')
			}
			else
			{
				this.createData.push( Object.freeze( {...data} ) );

				this.$eventBus.$emit('rest:model', this)
			}
		},

		addDeleteData(obj)
		{
			// consola.debug(`[${this.singletonCounter}]addUpdateData: `, obj)

			let foundIdx = -1
			let sendUpwards = false;

			if (typeof obj.id == 'undefined' || obj.id == -1)
			{
				if (this.modelId == -1)
				{
					sendUpwards = true;
				}
				else
				{
					obj.id = this.modelId
				}
			}

			if (typeof obj._$modelUrl == 'undefined' || !obj._$modelUrl)
			{
				if (!this.url)
				{
					sendUpwards = true;
				}
				else
				{
					obj._$modelUrl = this.url
				}
			}

			if (sendUpwards || obj._$modelUrl != this.url)
			{
				// this.$eventBus.$emit('rest:model', obj)
				this.sendDataToAncestor(obj, 'addDeleteData')
			}
			else
			{
				this.deleteData.push( Object.freeze( {...data} ) );

				this.$eventBus.$emit('rest:model', this)
			}
		},

		sendDataToAncestor( data, funcName )
		{
			let parent = this.$parent
			// let id = -1
			while ( parent )
			{
				// consola.debug('_componentTag: ', parent.$options._componentTag)
				if (parent.$options._componentTag == 'nuxt')
					break;
				if (parent.$options._componentTag == 'rest-model')
				{
					if (funcName)
						parent[funcName]( data )
					else
						parent.addUpdateData( data )
					break
				}

				if (parent == parent.$parent)
					break;

				parent = parent.$parent
			}
		},


		cancelCommit()
		{
			this.postData = [];
			this.createData = [];
			this.deleteData = [];
			this.is_saving = false;
		},


		asyncCommit()
		{
			let promises = [];
			if (this.postData.length > 0)
			{
				this.is_saving = true;
				for (let item of this.postData)
					consola.debug(`UPDATING ${this.url}: `, item)
				// return this.$axios.put(this.url , this.postData)
				let url = this.url;
				if (this.allLocales)	url += '/all';
				promises.push( this.$apiPut(url , this.postData)
								.then((param)=>
								{
									this.postData = [];
									this.is_saving = false;
									return param;
								})
								.catch( (error) =>
								{
									console.error(error);
									this.is_saving = false;
									return error;
								})
				);
			}
			if (this.createData.length > 0)
			{
				this.is_saving = true;
				// return this.$axios.put(this.url , this.postData)
				promises.push( this.$apiPost(this.url , this.createData)
								.then((param)=>
								{
									this.postData = [];
									// this.is_saving = false;
									return param;
								})
								.catch( (error) =>
								{
									console.error(error);
									// this.is_saving = false;
									return error;
								})
				);
			}
			if (this.deleteData.length > 0)
			{
				this.is_saving = true;
				// return this.$axios.put(this.url , this.postData)
				promises.push( this.$apiDelete(this.url , this.deleteData)
								.then((param)=>
								{
									this.postData = [];
									// this.is_saving = false;
									return param;
								})
								.catch( (error) =>
								{
									console.error(error);
									// this.is_saving = false;
									return error;
								})
				);
			}

			if (promises.length)
				return Promise.all(promises)
			else
				return Promise.resolve(null)
		},

		async commit()
		{
			consola.debug('saving...');
			return await this.asyncCommit()
		},
	},
}
