//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Modal from './modal-container'
import SmoothScrollbar from '~/lib/smooth-scrollbar-gsap/index.js';

export default {
	components:
	{
		Modal,
	},


	props:
	{
		prices: { type: Array, required: true },
	},

	// data()
	// {
	// 	return {
	// 	};
	// },

	computed:
	{
		...mapGetters(['isEditingPage', 'getString', 'lang', 'langShort','windowWidth', 'isMobile', 'isTablet', 'isTabletOrBigger', 'isLargerThanTabletLandscape', 'isLaptop', 'isLaptopOrBigger', 'isDesktop', 'windowWidth', 'scrollTop']),
	},

	watch:
	{

	},


	mounted()
	{
		if (process.client)
		{
			this.$freezeBodyScroll(true)
		}
	},

	beforeDestroy()
	{
		if (process.client)
		{
			this.$freezeBodyScroll(false)
		}
	},

	methods:
	{
		onClose()
		{
			this.destroySmoothScroll();
			this.$emit('close');
		},

		initSmoothScroll()
		{
			// consola.debug('Scrollbar init')
			let pageContent = this.$refs['text-container'];
			if (!pageContent)	return;
			this.smoothScrollbar = SmoothScrollbar.init(pageContent, {
													renderByPixels: false,
													// skewScale: window._disableSkew ? 0 : 0.01 * 0.5,
													// skewScale: 0.01 * 0.5,
													rafLoop: this.$raf,
													// tracksParent: document.body,
													// alwaysShowTracks: false,
													alwaysShowTracks: true,
												});
			// document.documentElement.classList.add('smooth-scroll');
			// consola.debug('scrollbar', this.smoothScrollbar);
		},

		destroySmoothScroll()
		{
			if (this.smoothScrollbar)
			{
				// let scrollbars = document.querySelectorAll('body > .scrollbar-track');
				// if (scrollbars)
				// {
				// 	for (let i = 0; i < scrollbars.length; i++)
				// 	{
				// 		document.body.removeChild(scrollbars[i]);
				// 	}
				// }
				this.smoothScrollbar.destroy();
			}
			this.smoothScrollbar = null;
		},

	}


}
