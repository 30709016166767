//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
	// name: 'rest-prop',
	props:
	{
		maxlength:
		{
			type: Number,
			default: 0
		},

		richtext:
		{
			type: Boolean,
			default: false
		},

		editable:
		{
			type: Boolean,
			default: false
		},

		tag:
		{
			type: String,
			default: 'span'
		},

		url:
		{
			type: String,
			default: ''
		},

		field:
		{
			type: String,
			default: ''
		},

		saveHtml:
		{
			type: Boolean,
			default: false
		},

		modelId:
		{
			type: Number,
			default: -1,
		}
	},

	data()
	{
		return {
			hasFocus: false,
			// is_dirty: false,
		}
	},

	computed:
	{
		isSlotEmpty() {	return !this.$slots.default || !this.$slots.default.innerText },
		emptyStyle()
		{
			if (this.isEditable && this.field && this.isSlotEmpty)
				return { '--placeholder-text': `"<${this.field}>"` }
			return {}
		}
	},

	watch:
	{
		hasFocus(newVal)
		{
			if ( newVal==false )
			{
				this.validate()
			}
		}
	},

	render(createElement)
	{
		return createElement(
			this.tag,
			{
				ref: 'tag',

				attrs:
				{
					// contenteditable: this.isEditable,
				},

				class:
				{
					// 'EditableText': true,
					'rest-placeholder': true,
				},

				style: this.emptyStyle,

				on:
				{
					focus: () => this.hasFocus = true,
					blur: () => this.hasFocus = false,
				}
			},
			this.$slots.default
		)
	},

	methods:
	{

	},

}
