//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
// import 'vue-simple-context-menu/dist/vue-simple-context-menu.css'
// import VueSimpleContextMenu from 'vue-simple-context-menu'
// import VueSimpleContextMenu from '~/components/vue-simple-context-menu'
import domel from '~/components/domel'
import InputClear from '~/components/input-clear'


var _singletonCounter = 0;

export default {
	name: 'multipanel-modal',

	components:
	{
		// 'context-menu': VueSimpleContextMenu,
		domel,
		InputClear,
	},

	props:
	{
		panels:
		{
			type: Array,
			required: true,
		},
	},

	data()
	{
		return {
			stateName: '',
			stateList: [],

			promise: null,
			resolve: null,
			reject: null,
			promiseList: [],
			singletonCounter: 0,
		}
	},

	// watch()
	// {
	// 	stateName(newVal)
	// 	{
	// 		consola.debug('stateName: ', newVal);
	// 	}
	// },

	computed:
	{
		...mapGetters(['lang', 'langShort', 'allLanguages']),

		refName()
		{
			return 'ctxtMenu' + this.singletonCounter;
		},

		idName()
		{
			return 'MultipanelModalCtxtMenu' + this.singletonCounter;
		},

		// stateName: {
		// 	get()
		// 	{
		// 		if (this.stateList.length)	return this.stateList[this.stateList.length-1];
		// 		return null;
		// 	},
		// 	set(val)
		// 	{
		// 		consola.debug('setting stateName to ', val)
		// 		let wrapper = { promise: null, resolve: null, reject: null };
		// 		wrapper.promise = new Promise( (resolve, reject) =>
		// 		{
		// 			wrapper.resolve = resolve;
		// 			wrapper.reject = reject;
		// 		} )
		// 		this.promiseList.push( wrapper );
		// 		this.stateList.push( val );
		// 	}
		// },
	},

	created()
	{
		consola.debug('multipanel modal created...')
		this.singletonCounter = ++_singletonCounter;

	},

	mounted()
	{
		consola.debug('multipanel modal mounted...', this.singletonCounter)

		this.promise = new Promise( (resolve, reject) =>
			{
				this.resolve = resolve;
				this.reject = reject;
			} )

		this.$on('resolve', (item)=>
		{
			consola.debug("$on('resolve') ", item)

			this.resolve({ item: item })
				this.$emit('close');
		})

		this.$on('reject', (item)=>
		{
			consola.debug("$on('reject') ", item)

			this.$emit('close');
		})

		this.$eventBus.$on('modal:resolve', (item)=>
		{
			consola.debug("$eventBus.$on('modal:resolve') ", item)
			// debugger;
			// if (this.promiseList.length > 0)
			// {
			// 	let wrapper = this.promiseList.pop();
			// 	if (wrapper.resolve)
			// 	{
			// 		wrapper.resolve({ item: item })
			// 		wrapper.resolve = null;
			// 		wrapper.reject = null;
			// 	}
			// 	this.stateList.pop();
			// }
			// if (this.promiseList.length == 0)
			this.resolve({ item: item })
				this.$emit('close');
		})

		this.$eventBus.$on('setState', (name) => this.setState(name) )
	},

	methods:
	{
		// gotoState(name)
		// {
		// 	this.stateName = name;
		// },

		// pushState(name, promise = null)
		// {
		// 	if (promise)
		// 	{
		// 		this.stateList.push(name)
		// 		this.promiseList.push(promise);
		// 	}
		// 	else
		// 	{
		// 		this.stateName = name;
		// 	}
		// },

		setState(name)
		{
			this.stateName = name;
			this.stateList = [name];
		},

		waitForPromise(promise)
		{
			this.promiseList = [promise];

			promise
				.then( x => { this.resolve && this.resolve(x); this.clearPromise() } )
				.catch( x => { this.reject && this.reject(x); this.clearPromise() } );
		},

		clearPromise()
		{
			this.promise = null;
			this.resolve = null;
			this.reject = null;
		},

		// popState()
		// {
		// 	let wrapper = this.promiseList.pop();
		// 	if (!wrapper)	return null;
		// 	if (wrapper.resolve)
		// 	{
		// 		wrapper.resolve(null)
		// 		wrapper.resolve = null;
		// 		wrapper.reject = null;
		// 	}
		// 	wrapper.promise = null;
		// 	return wrapper;
		// },

		getSelectionPromise()
		{
			return this.promise;

			// if (this.promiseList.length)
			// 	return this.promiseList[this.promiseList.length-1].promise;
			// return null;
		},

		// select(index)
		// {
		// 	this.selectedIndex = index;
		// 	// consola.debug('double-clicked '+index);
		// 	if (this.resolve)
		// 	{
		// 		this.resolve({ item: this.collection[index], domEl: this.$refs.item[index] })
		// 		this.resolve = null;
		// 		this.reject = null;
		// 	}
		// },

		emitClose()
		{
			// while (this.promiseList && this.promiseList.length > 0)
			// {
			// 	this.popState()
			// }
			if (this.reject)
			{
				this.reject(null);
				this.clearPromise();
			}
			this.$emit('close')
		}
	}
}
