//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
// import svgSymbol from '~/components/svg-symbol'
// import Domel from '~/components/domel.vue'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// WARNING: do NOT import it here because it will crash SSR.
// import { quillEditor } from 'vue-quill-editor'
// import { quillEditor } from 'vue-quill-editor/dist/ssr'


// let _editor = null;

let directives;
if (process.browser)
{
	const { quillEditor } = require('vue-quill-editor/dist/ssr')
	directives = process.browser ? { 'quill': quillEditor } : { };
}
else
{
	directives = {}
}

// const directives = process.browser ? { 'quill': quillEditor } : { };

const editorOption = Object.freeze( {
			// theme: 'bubble',
			placeholder: "<type content here>",
			modules: {
				toolbar: [
					['bold', 'italic', 'underline', 'strike'],
					[{ 'list': 'ordered'}, { 'list': 'bullet' }],
					[{ 'size': ['small', false, 'large', 'huge'] }],
					[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
					[{ 'color': [] }, { 'background': [] }],
					// [{ 'font': [] }],
					// [{ 'align': [] }],
					// ['link', 'image'],
					['link'],
					['clean']
				]
			}
		}
		);

export default {
	name: 'rich-textarea',

	directives,

	components:
	{
		// Domel,
		// svgSymbol,
	},

	props:
	{

		// editable:
		// {
		// 	type: Boolean,
		// 	default: false
		// },

		// tag:
		// {
		// 	type: String,
		// 	default: 'span'
		// },

		value:
		{
			type: String,
			default: ''
		},

		// content:
		// {
		// 	type: String,
		// 	default: '',
		// },

	},

	data()
	{
		return {
			content: '',
			// editorOption: {},
			editorOption,
			hasFocus: false,
		}
	},

	computed:
	{

	},

	watch:
	{
		value(newVal)
		{
			if (this.hasFocus)
			{
				(console.warn || consola.debug)('quill wont change content when focused');
				return;
			}
			this.content = newVal;
		}
	},

	created ()
	{
		this.content = this.value;
		// this.content = this.doubleBr( this.html );
	},

	mounted()
	{
		// this.onClick();
		// // consola.debug('content: ', this.content);
		this.$nextTick( () =>
		{
			this.registerEvents()
		} )
	},

	beforeDestroy()
	{
		this.unregisterEvents()
	},


	methods:
	{
		registerEvents()
		{
			// if (!this.isEditingPage)	return;

			// consola.debug('registerEvents')
			if (!this.$refs.editor || !this.$refs.editor.firstElementChild)
			{
				consola.debug('this.$refs.editor: null')
				return;
			}
			this.$refs.editor.firstElementChild.addEventListener('focus', this.onFocus)
			this.$refs.editor.firstElementChild.addEventListener('blur', this.onBlur)
		},

		unregisterEvents()
		{
			// if (!this.isEditingPage)	return;

			// consola.debug('unregisterEvents')
			if (!this.$refs.editor || !this.$refs.editor.firstElementChild)
			{
				consola.debug('this.$refs.editor: null')
				return;
			}
			this.$refs.editor.firstElementChild.removeEventListener('focus', this.onFocus)
			this.$refs.editor.firstElementChild.addEventListener('blur', this.onBlur)
		},

		onFocus(evt)
		{
			consola.debug('quil focused')
			this.hasFocus = true;
		},

		onBlur(evt)
		{
			consola.debug('quil blured')
			this.hasFocus = false;
			this.$emit('input', this.content)
		},

		doubleBr(str)
		{
			return str.replace(/<br>/gi, '<br><br>');
		},

		singleBr(str)
		{
			return str.replace(/<br><br>/gi, '<br>');
		},

		removeStyling()
		{
			function trek(node)
			{
				node.removeAttribute('style');
				for (let child of node.children)
					trek(child);
			}

			let parser = new DOMParser();
			let doc = parser.parseFromString(this.content, "text/html");
			if (doc)
			{
				let node = doc.body;
				trek(node);
				this.content = node.outerHTML;
			}

			// consola.debug(this.content);
		},

	},

}
