//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isTrident, isEdge } from '~/plugins/browser-detect'
import '~/assets/sprite/gen/spritemap.svg'

const _isIE = isTrident();
const _isEdge = isEdge();

export default {
	name: 'svg-symbol',

	// inheritAttrs: false,
	props:
	{
		// viewBox:
		// {
		// 	type: String,
		// 	default: '0 0 0 0'
		// },
		name:
		{
			type: String,
			required: true,
		},
		fallback:
		{
			type: String,
			default: '',
		},
	},
	computed:
	{
		isIE()
		{
			return _isIE;
		},
		imgPath()
		{
			if (this.fallback)
				return 'img/' + this.fallback + (this.fallback.indexOf('.') > -1 ? '' : '.png' );
			else
				return 'img/' + this.name + '.png';
		}
	},
	methods:
	{
		getSymbol()
		{
			var html = '<svg></svg>';

			if (process.browser)
			{
				var node = document.querySelector('#i-'+this.name);
				// IE11 does *NOT* support innerHTML for SVG nodes
				// html =  node.innerHTML;

				// here is the workaround
				const svgNode = node;
				const serializer = new XMLSerializer();

				html = Array.prototype.map.call(
								svgNode.childNodes,
								(child) => serializer.serializeToString(child)
							).join('');

				// console.log(this.name + ': ', html);

				//--------------

				html = '<svg viewBox="' + node.getAttribute('viewBox') + '" xmlns="' + node.getAttribute('xmlns') + '">' + html + '</svg>';
			}
			return html;
		}
	},
}
