//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;

export default {
	name: 'tag',

	props:
	{
		list:
		{
			type: Array,
			required: true,
		},

		itemKey:
		{
			type: String,
			required: true,
		}

	},

	methods:
	{
		onSelect(event, item, index)
		{
			event.preventDefault();
			event.stopPropagation();
			consola.debug('onSelect ', item, index)
			this.$emit('tag:select', { item, index });
		},

		onRemove(event, item, index)
		{
			event.preventDefault();
			event.stopPropagation();
			consola.debug('onRemove ', item, index)
			this.$emit('tag:remove', { item, index });
		}
	}

}
