//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
let addedYoutubeAPI = false;
let lastID = 1021;

export default {
	props:
	{
		videoId:
		{
			type: String,
			required: true,
		},

		bgImg:
		{
			type: String,
			default: '',
			// required: true
		},

		wrapperAspect:
		{
			type: Number,
			default: 0.565
		},

		videoWidth:
		{
			type: Number,
			default: 640
		},

		videoHeight:
		{
			type: Number,
			default: 360
		},

		alignX:
		{
			type: String,
			default: 'center'
		},

		alignY:
		{
			type: String,
			default: 'center'
		},

		loop:
		{
			type: Boolean,
			default: true
		},

		autoplay:
		{
			type: Boolean,
			default: true
		},

		endTimeSec:
		{
			type: Number,
			default: 0,
		},
	},

	data()
	{
		return {
			tag: null,
			firstScriptTag: null,
			iframe: null,
			player: null,
			showVideo: false,
			playing: false,
		}
	},

	watch:
	{
		showVideo(newVal)
		{
			consola.debug('showVideo: ', newVal);
			setTimeout(() => {
				this.calcCover();
			}, 10);
		}
	},

	computed:
	{
		playerID()
		{
			return 'player'+lastID;
		},

		youtubeEmbedUrl()
		{
			return `https://www.youtube.com/embed/${this.videoId}`;
		},

		videoAspect()
		{
			return this.videoHeight / this.videoWidth;
		},

		// bgImgStyle()
		// {
		// 	// return '';
		// 	return `background-image: url('${this.bgImg}');padding-bottom: ${this.wrapperAspect*100}%`;
		// },

		wrapperStyle()
		{
			// return '';
			return `padding-bottom: ${this.wrapperAspect*100}%;`;
		},


	},

	mounted()
	{
		const self = this;
		lastID++;
		if (process.browser)
		{

		}

	},

	methods:
	{

	}
}
